import axios from "axios";
import dayjs from "dayjs";
import axiosIntance from "../axiosInstance";
import axiosInstanceFiles from "../axiosInstanceFiles";
import { CLEARENCE, VehicleStatus } from "../utils";
import { IAuction } from "./auction-service";
import { IContainer } from "./container-service";
import { IUser } from "./user-service";

export enum VEHICLE_DOCUMENTS {
  IMAGES = 'images',
  DOCS = 'documents',
  INSPECTION = 'customsInspection',
  TECHNICAL_REPORT = 'technicalReport',
  INVOICE = 'invoice',
  TAX_ASSESSMENT = 'taxAssessment',
  TITLE = 'title',
  MOTUS_INVOICE ='motusInvoice',
  POA = 'powerOfAttorney',
  SHIPPING_INVOICE = 'shippingInvoice',
  PERSONAL_ID = 'personalId',
  PAYMENT_PROOF = 'paymentProof',
};
interface ISearchVehicle {
  count: number,
  data: IVehicle[]
}
export interface StatusHistory {
  date: string,
  status: keyof typeof VehicleStatus,
  note?: string
}
export interface IVehicle {
    id: string,
    carId?: string,
    _id: string,
    vin: string,
    status: keyof typeof VehicleStatus,
    users?: IUser[],
    type: keyof typeof VehicleStatus,
    stockNumber: string,
    user: IUser,
    documents?: any,
    warehouse?: IUser,
    images?: any,
    invoice?: any,
    customsInspection?: any,
    technicalReport?: any,
    taxAssessment?: any,
    title?: any,
    personalId?: any,
    paymentProof?: any,
    shippingInvoice?: any,
  motusInvoice?: any,
  warehouseId?: string,
  powerOfAttorney?: any
  destination?: string,
  finalDestination?: string,
  expectedDate?: string,
  titleSentDate?: string,
  containerId?: any;
  containerName?: string,
  container?: IContainer | null,
  auctionLocation?: string,
  statusHistory: StatusHistory[],
  notes?: string,
  adminNotes?: string;
  customerNotes?: string;
  auction?: IAuction,
  keys: boolean,
  released: boolean,
  onhold?: boolean,
  titleReceived?: any,
  paid: boolean,
  documentsReceived: boolean,
  description: string,
  atcNumber?: string,
  atbPosNumber?: string,
customsClearance?: CLEARENCE,
  shippingLine?: any,
  portOfDestination?: string,
  portOfDeparture?: string,
  titleHandover?: boolean,
  handedOverTo?: string,
  titleReceivedDate?: string,
  pickupDate?: string,
  driverName?: string,
  truckCompany?: string,
  licensePlate?: string,
    createdBy?: string,
    createdAt: string,
  updatedAt: string
  otherFiles: {
    name: string,
    file: any
  }[],
  otherDocuments: {[key: string]: any}[]
}
export interface ICreateVehicle extends IVehicle {
  userIds?: string[],
  users?: IUser[],
  warehouseId?: string,
  auctionId?: string,
  containerId?: string | null,
}
export interface IVehicleImageObject {
  imagesToAdd?: any,
  imagesToDelete?: any,
}
export interface IVehicleDocumentObject {
  documentsToAdd?: any,
  documentsToDelete?: any,
}

export interface ISignalMessage {
  sender: string,
  recipients: string[],
  message: string;
}
interface IVehicles {
  count: number,
  data: IVehicle[]
}
export interface IDateRange {start: string; end: string};
export const getVehicle = (vehicleId: string) => {
  return axiosIntance.get<IVehicle>(`/vehicles/${vehicleId}`);
};
export const createVehicle = (values: ICreateVehicle) => {
  return axiosIntance.post<IVehicle>('/vehicles', values);
}
export const updateVehicle = (values: ICreateVehicle) => {
  return axiosIntance.put<IVehicle>(`/vehicles/${values.id}`, values);
}
export const searchVehicle = (vin?: string, useStockNumber?: boolean) => {
  return axiosIntance.get<IVehicle>(`/search`, {
    params: {
      vin: useStockNumber ? undefined : vin,
      stockNumber: useStockNumber ? vin : undefined,
    }
  });
};
export const decodeVinNhtsa = (vin?: string) => {
  return axios.get<any>(`https://vpic.nhtsa.dot.gov/api/vehicles/decodevinvalues/${vin}?format=json`, {
  });
};
export const searchEvents = (dateRange: IDateRange) => {
  return axiosIntance.get(`/search/events?startDate=${dateRange.start}&endDate=${dateRange.end}`);
}
export const getVehicles = (status?: keyof typeof VehicleStatus, myVehicles?: boolean) => {
  return axiosIntance.get<IVehicles>('/vehicles',{
    params: {
      // page: page + 1,
      // pageSize,
      status,
      myVehicles: myVehicles || undefined
    }
});
};
export const vehiclesSearch = () => {
  return axiosIntance.get<IVehicle[]>('/vehicles/search');
};
export const deleteVehicle = (vehicleId: string) => {
    return axiosIntance.delete<IVehicle>(`/vehicles/${vehicleId}`);
};
export const uploadVehicleDocuments = (vehicleId: string, formData: any) => {
    return axiosInstanceFiles.post(`/files/upload/files/vehicle/${vehicleId}`, formData);
};
export const uploadVehicleImages = (vehicleId: string, formData: any) => {
    return axiosInstanceFiles.post(`/files/upload/images/vehicle/${vehicleId}`, formData);
};
export const deleteVehicleImages = (vehicleId: string, imagesToDelete: string[]) => {
  return axiosIntance.put(`/files/remove/vehicles/${vehicleId}/images`, {files: imagesToDelete});
};
export const deleteVehicleDocuments = (vehicleId: string, documentsToDelete: string[]) => {
  return axiosIntance.put(`/files/remove/vehicles/${vehicleId}/documents`, {files: documentsToDelete});
};
export const bulkUpdateVehicles = (vehicleIds: string[], status: VehicleStatus) => {
  return axiosIntance.put(`/vehicles/bulk/updata/status`, {vehicleIds, status});
};
export const sendSignalMessage = (signalMessage: ISignalMessage) => {
  return axiosIntance.post(`/vehicles/send-signal-message`, signalMessage);
};
export const updateVehicleNotes = (vehicleId: string, notes?: string) => {
  return axiosIntance.put<IVehicle>(`/vehicles/${vehicleId}/notes`, {customerNotes: notes || ""} );
}
export const searchVehicleInput = (vin?: string, status?: string, noContainer?: boolean) => {
  return axiosIntance.get<ISearchVehicle>(`/vehicles/search`, {
      params: {
        page: 1,
        pageSize: 10,
        vin,
        status,
        noContainer
      }
  });
};
export const createVehicleMethod = async (values: ICreateVehicle & IVehicleImageObject) => {
  const validDate = dayjs(values.expectedDate).isValid();
  const validSent = dayjs(values.titleSentDate).isValid();  
    const valuesMod: ICreateVehicle = {
      ...values,
      documents: undefined,
      images: undefined,
      expectedDate: validDate ? values.expectedDate : undefined,
      titleSentDate: validSent ? values.titleSentDate : undefined,
      title: values.title && undefined,
      motusInvoice: values.motusInvoice && undefined,
      powerOfAttorney: values.powerOfAttorney && undefined,
      invoice: values.invoice && undefined,
      customsInspection: values.customsInspection && undefined,
      technicalReport: values.technicalReport && undefined,
      taxAssessment: values.taxAssessment && undefined,
  };
  const createdVehicle = await createVehicle(valuesMod);
  const vehicleData = createdVehicle.data;
    const formData = new FormData();    //formdata object
    if (values?.invoice) {
      formData.append(VEHICLE_DOCUMENTS.INVOICE, values?.invoice[0]);
    }
    if (values?.customsInspection) {
      formData.append(VEHICLE_DOCUMENTS.INSPECTION, values?.customsInspection[0]);
    } 
    if (values?.technicalReport) {
      formData.append(VEHICLE_DOCUMENTS.TECHNICAL_REPORT, values?.technicalReport[0]);
    }
    if (values?.taxAssessment) {
      formData.append(VEHICLE_DOCUMENTS.TAX_ASSESSMENT, values?.taxAssessment[0]);
    }
    if (values?.title) {
      formData.append(VEHICLE_DOCUMENTS.TITLE, values?.title[0]);
    }
    if (values?.motusInvoice) {
      formData.append(VEHICLE_DOCUMENTS.MOTUS_INVOICE, values?.motusInvoice[0]);
    }
    if (values?.powerOfAttorney) {
      formData.append(VEHICLE_DOCUMENTS.POA, values?.powerOfAttorney[0]);
  }
        for (let i = 0; i < values.otherFiles.length; i++) {      
        if (values.otherFiles[i].name) {
        // @ts-ignore
        
      formData.append(`${values.otherFiles[i].name}`, values.otherFiles[i].file ?  values.otherFiles[i].file[0] : null);
      }                                                
    }
  await uploadVehicleDocuments(vehicleData.id, formData);
    return createdVehicle;
}
export const updateVehicleMethod = async (values: ICreateVehicle & IVehicleImageObject & IVehicleDocumentObject) => {
    const validDate = dayjs(values.expectedDate).isValid();
    const validSent = dayjs(values.titleSentDate).isValid();
    const formData = new FormData();
  if (values?.invoice) {
      formData.append(VEHICLE_DOCUMENTS.INVOICE, values?.invoice[0]);
    }
    if (values?.customsInspection) {
      formData.append(VEHICLE_DOCUMENTS.INSPECTION, values?.customsInspection[0]);
    } 
    if (values?.technicalReport) {
      formData.append(VEHICLE_DOCUMENTS.TECHNICAL_REPORT, values?.technicalReport[0]);
    }
    if (values?.taxAssessment) {
      formData.append(VEHICLE_DOCUMENTS.TAX_ASSESSMENT, values?.taxAssessment[0]);
    }
    if (values?.title && typeof values.title !== "string") {
        formData.append(VEHICLE_DOCUMENTS.TITLE, values?.title[0]);
    }
    if (values?.motusInvoice) {
      formData.append(VEHICLE_DOCUMENTS.MOTUS_INVOICE, values?.motusInvoice[0]);
    }
    if (values?.powerOfAttorney) {
      formData.append(VEHICLE_DOCUMENTS.POA, values?.powerOfAttorney[0]);
    }
      for (let i = 0; i < values.otherFiles.length; i++) {      
        if (values.otherFiles[i].name) {
        // @ts-ignore
          //values.otherDocuments[values.otherFiles[i].name] = null;
          formData.append(`${values.otherFiles[i].name}`, values.otherFiles[i].file ?  values.otherFiles[i].file[0] : null); 
      }
                                                      
    }
    const valuesMod: ICreateVehicle = {
      ...values,
      documents: undefined,
      images: undefined,
      userIds: values.users && values.users?.map((user: IUser) => {
        return user._id || user.id
      }),
      expectedDate: validDate ? values.expectedDate : undefined,
      titleSentDate: validSent ? values.titleSentDate : undefined,
      title: values.title && undefined,
      motusInvoice: values.motusInvoice && undefined,
      powerOfAttorney: values.powerOfAttorney && undefined,
      invoice: values.invoice && undefined,
      customsInspection: values.customsInspection && undefined,
      technicalReport: values.technicalReport && undefined,
      taxAssessment: values.taxAssessment && undefined,
  };
    const updatedVehicle = await updateVehicle(valuesMod);
    const { id } = updatedVehicle.data;
    await uploadVehicleDocuments(id, formData);
}