import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import ErrorIcon from "@mui/icons-material/Error";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import NotesIcon from "@mui/icons-material/Notes";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { FormControlLabel, FormGroup, IconButton, Switch } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridRenderCellParams,
  GridSelectionModel,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { ActionMenu } from "../../components/BasicTable/ActionMenu";
import { ConfirmDialog } from "../../components/CommonDialog/ConfirmDialog";
import { QuickStatusFilter } from "../../components/QuickStatusFilter";
import { getCurrentUser } from "../../services/auth-service";
import { IUser } from "../../services/user-service";
import {
  deleteVehicle,
  getVehicles,
  IVehicle,
  updateVehicle,
} from "../../services/vehicle-service";
import {
  CLEARENCE,
  GridActions,
  GridTolbarLocale,
  UserRoles,
  VehicleStatus
} from "../../utils";
import { CustomToolbar } from "../../utils/CustomToolbar";
import { NotesDialog } from "./NotesDialog";
import { VehicleDetails } from "./VehicleDetails";
import { VehicleDialog } from "./VehicleDialog";
import { VehicleStatusDialog } from "./VehicleStatusDialog";

const VehicleActions = {
  ADD: "add-vehicle",
  EDIT: "edit-vehicle",
};

const EllipsisDiv = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 180px;
    display: block;`;
export const Vehicles: FC = () => {
  
  const { t } = useTranslation();
  const user = getCurrentUser();
  const isClient = user?.role === UserRoles.CLIENT;
  const [muiTableKey, setMuiTableKey] = useState<any>(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const [onlyMyVehicles, setOnlyMyVehicles] = useState<boolean>(Boolean(searchParams.get("myVehicles")));
  const activeStatus = searchParams.get("status") as keyof typeof VehicleStatus;
  const page = searchParams.get("page") || "0";
  const params = useParams();
  const [pageState, setPageState] = useState(
    Number(page) > 0 ? Number(page) : 0
  );
  const [vehicles, setVehicles] = useState<IVehicle[]>([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(params.action === VehicleActions.ADD);
  const [openNotes, setOpenNotes] = useState(false);
  const [vehicleToEdit, setVehicleToEdit] = useState<string | undefined>(
    undefined
  );
  const [vehicleToPreview, setVehicleToPreview] = useState<string | undefined>(
    undefined
  );
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [openStatusUpdate, setOpenStatusUpdate] = useState(false);
  const [selection, setSelection] = useState<GridSelectionModel>([]);
  const [vehicleToDelete, setVehicleToDelete] = useState<IVehicle | undefined>(
    undefined
  );

  const navigate = useNavigate();
  useEffect(() => {
    if (params.vin) {
      setVehicleToPreview(params.vin);
    }
  }, [params.vin]);

  const resetFilters = async () => {
    setMuiTableKey(muiTableKey+1)
    navigate("/vehicles")
  }
  const actionMenu = (params: GridRenderCellParams) => {
    return (
      <ActionMenu>
        {isClient && (
          <Tooltip title={t("Add notes")}>
            <IconButton onClick={() => handleToggleOpenNotesEdit(params.row)}>
              <NotesIcon fontSize="medium" />
            </IconButton>
          </Tooltip>
        )}
        {/* {user?.role === UserRoles.CLIENT && (
          <IconButton onClick={() => handleToggleOpenNotesEdit(params.row)}>
            <FolderIcon fontSize="medium" />
          </IconButton>
        )} */}
        {!isClient && (
          <Tooltip title={t("Edit")}>
          <IconButton onClick={() => handleToggleOpenEdit(params.row)}>
            <EditIcon fontSize="medium" />
          </IconButton>
          </Tooltip>
        )}
        <Tooltip title={t("View")}>
          <IconButton onClick={() => setVehicleToPreview(params.row.id)}>
            <VisibilityIcon fontSize="medium" />
          </IconButton>
        </Tooltip>
        
        {!isClient && (
          <Tooltip title={t("Delete")}>
            <IconButton onClick={() => handleDeleteVehicle(params.row)}>
              <DeleteForeverIcon color="error" fontSize="medium" />
            </IconButton>
          </Tooltip>
        )}
      </ActionMenu>
    );
  };
  const actionColumn: GridColDef = {
    field: "actions",
    sortable: false,
    //flex: 1,
    width: 130,
    headerAlign: "center",
    align: "center",
    headerName: t("Actions"),
    renderCell: (params: GridRenderCellParams) => {
      return actionMenu(params);
    },
  };
  const adminColumns: GridColDef[] = [
    {
      field: "user.nickname",
      headerName: t("Clients"),
      width: 150,
      valueGetter: (params) => {
        return params.row.users
          ?.map((user: IUser) => user.nickname || user.firstName)
          .join(",");
      },
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div>
            <Tooltip title={params.value}>
            <EllipsisDiv>{params?.value}</EllipsisDiv>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  const pageSize = 10;
  const columns: GridColDef[] = [
    ...(!isClient ? adminColumns : []),
    { 
      field: "carID",
      headerName: t("ID"),
      width: 150,
      renderCell: (params) => {
        return `${params.row?.carId}-${new Date(params.row?.createdAt).getFullYear()}`
      },
    },
    { 
      field: "description",
      headerName: t("Description"),
      width: 200,
    },

    {
      field: "vin",
      headerName: t("VIN"),
      width: 100,
      valueFormatter: (params) => {
        return params.value
          ? params.value.substring(params.value.length - 6)
          : "";
      },
    },
    { field: "containerName", headerName: t("Container"), width: 150 },
    { field: "portOfDeparture", headerName: t("Origin")},
    { 
      field: "portOfDestination",
      headerName: t("Destination port"),
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div>
            <Tooltip title={params.value}>
            <EllipsisDiv>{params?.value}</EllipsisDiv>
            </Tooltip>
          </div>
        );
      },
      width:150,
    },
    {
      field: "expectedDate",
      headerName: t("Expected date"),
      sortable: true,
      width: 140,
      valueFormatter: (params) => {
        return params.value ? dayjs(params.value).format("DD/MM/YYYY") : "";
      },
    },
    {
      field: "warehouse.nickname",
      headerName: t("User"),
      width: 130,
      valueGetter: (params) => params.row.warehouse?.nickname,
    },
    {
      field: "status",
      headerName: t("Status"),
      width: 200,
      renderCell: (params: GridRenderCellParams) => {
        return t(VehicleStatus[params.value as keyof typeof VehicleStatus]);
      },
    },
    {
      field: "documentsReceived",
      headerName: t("Documents received"),
      width: 120,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams) => {
        const { documentsReceived } = params.row;
        return documentsReceived ? (
          <Tooltip title={t("Documents received")}>
            <CheckCircleIcon style={{ cursor: "pointer" }} color="primary" />
          </Tooltip>
        ) : (
          <Tooltip title={t("Documents not received")}>
             <ErrorIcon color="error" />
          </Tooltip>
        );
      },
    },
    {
      field: "released",
      headerName: t("Released"),
      sortable: true,
      headerAlign: "center",
      align: "center",
      width: 120,
      renderCell: (params: GridRenderCellParams) => {
        const { released } = params.row;
        return released ? (
          <Tooltip title={t("Vehicle released")}>
            <LockOpenIcon style={{ cursor: "pointer" }} color="primary" />
          </Tooltip>
        ) : (
          <Tooltip title={t("Vehicle not released")}>
            <LockIcon color="error" />
          </Tooltip>
        );
      },
    },
    {
      field: "clearence",
      headerName: t("Clearance"),
      sortable: true,
      headerAlign: "center",
      align: "center",
      width: 120,
      renderCell: (params: GridRenderCellParams) => {
        const { customsClearance } = params.row;
        return customsClearance === CLEARENCE.CLEARED ? (
          <Tooltip title={t("Vehicle cleared")}>
            <CheckCircleIcon style={{ cursor: "pointer" }} color="primary" />
          </Tooltip>
        ) : (
          <Tooltip title={t("Vehicle not cleared")}>
            <ErrorIcon color="error" />
          </Tooltip>
        );
      },
    },
    {
      field: "paid",
      headerName: t("Paid"),
      sortable: true,
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        const { paid } = params.row;
        return paid ? (
          <Tooltip title={t("Invoice paid")}>
            <CheckCircleIcon style={{ cursor: "pointer" }} color="primary" />
          </Tooltip>
        ) : (
          <Tooltip title={t("Invoice unpaid")}>
            <ErrorIcon color="error" />
          </Tooltip>
        );
      },
    },
    ...[actionColumn],
  ];
  const handleDeleteVehicle = (vehicle: IVehicle) => {
    setVehicleToDelete(vehicle);
    setDeleteOpen(true);
  };
  const handleConfirmDelete = async () => {
    try {
      if (vehicleToDelete) await deleteVehicle(vehicleToDelete.id);
      toast.success(t("Vehicle deleted successfully"));
    } catch (error) {
      console.log(error, "ERROR");
      toast.error(t("Coś poszło nie tak"));
    }
    loadVehicles();
    setVehicleToDelete(undefined);
    setDeleteOpen(false);
  };
  const handleToggleOpenNotesEdit = (vehicle?: IVehicle) => {
    setOpenNotes(!openNotes);
    setVehicleToEdit(vehicle?.id);
  };
  const handleToggleOpenEdit = (vehicle?: IVehicle) => {
    setOpen(true);
    setVehicleToEdit(vehicle?.id);
  };
  const handleToggleOpen = () => {
    setVehicleToEdit(undefined);
    setOpen(!open);
  };
  const handleToggleOpenDetails = () => {
    setVehicleToPreview(undefined);
    //navigate(!params.vin ? `/vehicles/view/${vehicle?.id}` : "/vehicles");
  };
  const loadVehicles = async () => {
    setLoading(true);
    const { data: vehiclesData } = await getVehicles(
      // pageState,
      activeStatus || undefined,
      onlyMyVehicles
    );
    setVehicles(vehiclesData.data);
    //setTotalCount(vehiclesData.count);
    setVehicleToEdit(undefined);
    setOpen(false);
    setOpenNotes(false);
    setLoading(false);
    setOpenStatusUpdate(false);
  };
  const processRowUpdate = (newRow: IVehicle) => {
    toast.promise(updateVehicle(newRow), {
      loading: "Loading...",
      success: (res) => {
        return `Successfully updated vehicle status to : ${newRow?.status}`;
      },
      error: "Error when updating vehicle status",
    });
    return newRow;
  };
  const handleStatusClick = (status: any) => {
    if (status === activeStatus) {
      // 👇️ delete each query param
      searchParams.delete("status");

      // 👇️ update state after
      setSearchParams(searchParams);
      return;
    }
    searchParams.set("status", status);
    setSearchParams(searchParams);
  };
  useEffect(() => {
    if(isClient) {
      return;
    }
    searchParams.set("onlyMyVehicles", onlyMyVehicles ? "1" : "0");
    setSearchParams(searchParams);
  }, [onlyMyVehicles, searchParams, setSearchParams])
  const statuses = Object.keys(VehicleStatus).map((key) => {
    return {
      key,
      name: VehicleStatus[key as keyof typeof VehicleStatus],
    };
  });
  const onPageChange = (page: number, details: GridCallbackDetails<any>) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
    setPageState(page);
  };
  useEffect(() => {
    loadVehicles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStatus, onlyMyVehicles]);


  return (
    <Box sx={{ flexGrow: 1 }}>
      <GridActions>
        <Typography variant="h4">{t("Vehicles")}</Typography>
        <div
          style={{
            display: "flex",
            gap: 5,
          }}
        >
          {!isClient && (
            <Button variant="contained" onClick={handleToggleOpen}>
              {t("Add Vehicle")}
            </Button>
          )}
          <Button variant="contained" onClick={resetFilters}>
              {t("Clear filters")}
            </Button>
        </div>
      </GridActions>
      <GridActions>
        <QuickStatusFilter
          activeStatus={activeStatus}
          onClick={handleStatusClick}
          statuses={statuses}
        />
        <div>
          <ConfirmDialog
            handleConfirm={handleConfirmDelete}
            handleClose={() => setDeleteOpen(false)}
            open={deleteOpen}
            title={t("Are you sure to delete this vehicle?")}
          >
            {t("Deleting vehicle")} <strong>{vehicleToDelete?.description}</strong>
          </ConfirmDialog>
          {openStatusUpdate && (
            <VehicleStatusDialog
              vehicles={vehicles.filter((vehicle: IVehicle) => {
                return selection.includes(vehicle.id);
              })}
              submitCallback={loadVehicles}
              handleToggleOpen={() => {
                setOpenStatusUpdate(!openStatusUpdate);
              }}
            />
          )}
          {/* vehicle edit/create form */}
          {open && (
            <VehicleDialog
              vehicleId={vehicleToEdit}
              submitCallback={loadVehicles}
              handleToggleOpen={handleToggleOpen}
            />
          )}
          {openNotes && (
            <NotesDialog
              vehicleId={vehicleToEdit}
              submitCallback={loadVehicles}
              handleToggleOpen={handleToggleOpenNotesEdit}
            />
          )}
          {vehicleToPreview && (
            <VehicleDetails
              vehicleId={vehicleToPreview}
              handleToggleOpen={() => handleToggleOpenDetails()}
            />
          )}
        </div>
      </GridActions>
      {!isClient && (
          <FormGroup>
            <FormControlLabel control={<Switch onChange={(event) => {
              setOnlyMyVehicles(event.target.checked)
            }} checked={onlyMyVehicles} />} label={t("Only my vehicles")} />
          </FormGroup>
      )}

      <div style={{ width: "100%" }}>
        <DataGrid
          autoHeight 
          key={muiTableKey}
          initialState={{
            sorting: {
              sortModel: [{ field: "createdAt", sort: "desc" }],
            },
            columns: {
              columnVisibilityModel: {
                // Hide columns containerName, the other columns will remain visible
                createdAt: !isMobile,
              },
            },
          }}
          onPageChange={onPageChange}
          // rowCount={totalCount}
          experimentalFeatures={{ newEditingApi: true }}
          loading={loading}
          // selectionModel={selection}
          pagination
          //paginationMode="server"
          processRowUpdate={processRowUpdate}
          onSelectionModelChange={(itm) => setSelection(itm)}
          components={{ Toolbar: CustomToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          disableSelectionOnClick
          rows={vehicles}
          page={pageState}
          columns={columns}
          pageSize={pageSize}
          localeText={GridTolbarLocale(t)}
          // rowsPerPageOptions={[5, 10, 20, 50]}
          //checkboxSelection
        />
      </div>
    </Box>
  );
};
