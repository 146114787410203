import AccountBoxIcon from "@mui/icons-material/AccountBox";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CropFreeIcon from "@mui/icons-material/CropFree";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DirectionsTransitIcon from "@mui/icons-material/DirectionsTransit";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import DownloadIcon from "@mui/icons-material/Download";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import GradingIcon from "@mui/icons-material/Grading";
import GridViewIcon from "@mui/icons-material/GridView";
import ListAltIcon from "@mui/icons-material/ListAlt";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import NotesIcon from "@mui/icons-material/Notes";
import PaidIcon from "@mui/icons-material/Paid";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import StarIcon from "@mui/icons-material/Star";
import TourIcon from "@mui/icons-material/Tour";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import React, { useMemo } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { ReactImageGalleryItem } from "react-image-gallery";
import { GoogleDriveGallery } from "../../components/GoogleDriveGallery";
import StepperComponent from "../../components/Stepper";
import VerticalStepper from "../../components/Stepper/VerticalStepper";
import { FormDivider } from "../../components/styled/FormDivider";
import { getCurrentUser } from "../../services/auth-service";
import { IContainer } from "../../services/container-service";
import {
  IShippingLine,
  getShippingLine,
} from "../../services/shipping-line-service";
import { IUser } from "../../services/user-service";
import { IVehicle } from "../../services/vehicle-service";
import {
  FinalDestinations,
  UserRoles,
  VehicleStatus,
  copyText,
  decodeVin,
} from "../../utils";
import { AddDocuments } from "./AddDocuments";
import { VehicleDocumentsPreview } from "./VehicleDocumentsPreview";
export const VehicleStatusIcons = {
  TRANSIT: <LocationOnIcon />,
  LOADED: <DirectionsBoatIcon />,
  UNLOADED: <WarehouseIcon />,
  DELIVERED: <SportsScoreIcon />,
  LOADED_ON_THE_TRUCK: <LocalShippingIcon />,
  AT_TERMINAL: <LocalShippingIcon />,
  TRANSHIPMENT: <DirectionsBoatIcon />,
  IN_TRANSIT: <DirectionsTransitIcon />,
  NEW: <StarIcon />,
  DOCUMENTS_COMPLETED: <DocumentScannerIcon />,
  CLEARED: <DoneOutlineIcon />,
  PAID: <PaidIcon />,
  PICKED_UP: <LocalShippingIcon />,
};
interface IVehicleDetailsProps {
  images?: ReactImageGalleryItem[];
  vehicle?: IVehicle;
  share?: boolean;
}
export const VehicleDetailsForm: React.FC<IVehicleDetailsProps> = ({
  images,
  vehicle,
  share,
}) => {
  const { t } = useTranslation();
  const { useState, useEffect, useCallback } = React;
  //const containerState = vehicle?.containerId || vehicle?.container;
  const user = getCurrentUser();
  const [isLoading, setIsLoading] = useState(true);
  const [openDocModal, setOpenDocModal] = React.useState(false);
  const [openAddDocModal, setOpenAddDocModal] = React.useState(false);
  const [shippingLine, setShippingLine] = useState<IShippingLine | null>(null);
  const [containerState, setContainerState] = useState<
    IContainer | undefined | null
  >(vehicle?.container);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);
  const toggleDocumentsModal = () => {
    setOpenDocModal(!openDocModal);
  };
  const toggleAddDocumentsModal = () => {
    setOpenAddDocModal(!openAddDocModal);
  };

  const trackContainer = async (container?: IContainer | undefined | null) => {
    if (!container) {
      return;
    }
    const { data } = await getShippingLine(container.shippingLineId);

    // window.open(
    //   `https://tools.tritoncontainer.com/tritoncontainer/unitStatus/show/${containerNumber}`
    // );
  };
  const downloadImages = () => {
    if (!images) return;

    const zip = new JSZip();
    const folder = zip.folder("images"); // folder name where all files will be placed in

    images?.forEach((image) => {
      const url = image.original;
      const blobPromise = fetch(url).then((r) => {
        if (r.status === 200) return r.blob();
        return Promise.reject(new Error(r.statusText));
      });
      const name = url.substring(url.lastIndexOf("/") + 1);
      folder?.file(name, blobPromise);
    });

    zip
      .generateAsync({ type: "blob" })
      .then((blob) => saveAs(blob, vehicle?.description));
  };
  const steps = vehicle?.statusHistory?.map((status, index) => {
    return {
      label: `${t(VehicleStatus[status.status])} ${
        status ? dayjs(status.date).format("YYYY-MM-DD") : ""
      } ${status?.note || ""}`,
      active: true,
      completed: true,
      // @ts-ignore
      icon: VehicleStatusIcons[status.status],
      // icon: VehicleStatusIcons[vehicleStatus.status](),
    };
  });
  const isClient = user?.role === UserRoles.CLIENT;
  const fetchShippingLine = useCallback(async (container: IContainer) => {
    const { data } = await getShippingLine(container.shippingLineId);

    setShippingLine(data);
  }, []);
  useEffect(() => {
    if (containerState) {
      fetchShippingLine(containerState);
    }
  }, [containerState, fetchShippingLine]);
  const getDocsColor = useMemo(() => {
    if (vehicle?.powerOfAttorney && vehicle?.invoice) {
      return "primary";
    }
    return "error";
  }, [vehicle]);
  return (
    <>
      <Grid container spacing={2}>
        <Grid container item xs={12} md={6}>
          <Grid item xs={12}>
            <FormDivider variant="fullWidth">{t("Images")}</FormDivider>
          </Grid>
          <Grid item xs={12}>
            {vehicle && vehicle.vin && (
              <GoogleDriveGallery vin={vehicle?.vin} />
            )}
          </Grid>
        </Grid>
        <Grid container item xs={12} md={6}>
          <Grid item xs={12}>
            <FormDivider variant="fullWidth">
              {t("Main Information")}
            </FormDivider>
          </Grid>
          {/* LEFT PART */}
          <Grid item md={6}>
          <Box sx={{ display: "flex" }}>
              <ListItemButton
                style={{
                  flexGrow: 0,
                }}
              >
                <Tooltip title={t("ID")}>
                  <ListItemIcon>
                    <FormatListNumberedIcon color="primary" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary={vehicle?.createdAt ? `${vehicle?.carId} - ${new Date(vehicle?.createdAt).getFullYear()}` : ""} />
              </ListItemButton>
              <ListItemButton
                style={{
                  flexGrow: 0,
                }}
                onClick={() =>
                  vehicle?.vin &&
                  copyText(`${vehicle.carId} - ${new Date(vehicle.createdAt).getFullYear()}`, t("ID copied successfully"))
                }
              >
                <Tooltip title={t("Copy ID")}>
                  <ListItemIcon>
                    <ContentCopyIcon fontSize="small" color="primary" />
                  </ListItemIcon>
                </Tooltip>
              </ListItemButton>
            </Box>
            <Box sx={{ display: "flex" }}>
              <ListItemButton
                style={{
                  flexGrow: 0,
                }}
                onClick={() => decodeVin(vehicle?.vin || "")}
              >
                <Tooltip title={t("VIN (click for details)")}>
                  <ListItemIcon>
                    <CropFreeIcon color="primary" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary={vehicle?.vin} />
              </ListItemButton>
              <ListItemButton
                style={{
                  flexGrow: 0,
                }}
                onClick={() =>
                  vehicle?.vin &&
                  copyText(vehicle?.vin, t("Vin copied successfully"))
                }
              >
                <Tooltip title={t("Copy vin")}>
                  <ListItemIcon>
                    <ContentCopyIcon fontSize="small" color="primary" />
                  </ListItemIcon>
                </Tooltip>
              </ListItemButton>
            </Box>
            <ListItemButton onClick={() => {}}>
              <Tooltip title={t("Description")}>
                <ListItemIcon>
                  <DirectionsCarIcon color="primary" />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={vehicle?.description} />
            </ListItemButton>
            {user && vehicle?.users && (
              <ListItemButton onClick={() => {}}>
                <Tooltip title={t("Customer")}>
                  <ListItemIcon>
                    <PersonIcon color="primary" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  primary={t("Customer")}
                  secondary={vehicle?.users
                    ?.map((user: IUser) => {
                      return user.firstName;
                    })
                    .join(",")}
                />
              </ListItemButton>
            )}
            {((user && vehicle?.user?.phone) || share) && (
              <ListItemButton
                onClick={() =>
                  vehicle?.user?.phone &&
                  copyText(vehicle?.user?.phone, t("Phone copied successfully"))
                }
              >
                <Tooltip title={t("Client")}>
                  <ListItemIcon>
                    <PhoneIcon color="primary" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  primary={t("Client phone")}
                  secondary={vehicle?.user ? `${vehicle.user.phone}` : ""}
                />
              </ListItemButton>
            )}
            {vehicle?.warehouse && (
              <ListItemButton
                onClick={() =>
                  vehicle?.warehouse &&
                  copyText(
                    vehicle.warehouse.email,
                    t("E-mail copied successfully")
                  )
                }
              >
                <Tooltip title={t("Assistant")}>
                  <ListItemIcon>
                    <WarehouseIcon color="primary" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  primary={`${t("Assistant")} - ${
                    vehicle?.warehouse?.firstName
                  }`}
                  secondary={vehicle?.warehouse ? vehicle.warehouse.email : ""}
                />
              </ListItemButton>
            )}
            {((user && images && images.length > 0) || share) && (
              <ListItemButton onClick={() => downloadImages()}>
                <Tooltip title={t("Download all images")}>
                  <ListItemIcon>
                    <DownloadIcon color="primary" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary={t("Download all images")} />
              </ListItemButton>
            )}
          </Grid>
          {/* RIGHT PART */}
          <Grid item md={6}>
            {vehicle?.destination && (
              <ListItemButton onClick={() => {}}>
                <Tooltip title={t("Destination")}>
                  <ListItemIcon>
                    <TourIcon color="primary" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary={vehicle?.destination} />
              </ListItemButton>
            )}
            {vehicle?.finalDestination && (
              <ListItemButton onClick={() => {}}>
                <Tooltip title={t("Destination warehouse")}>
                  <ListItemIcon>
                    <SportsScoreIcon color="primary" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  primary={
                    vehicle?.finalDestination
                      ? FinalDestinations[vehicle?.finalDestination]
                      : ""
                  }
                />
              </ListItemButton>
            )}
            {vehicle?.expectedDate && (
              <ListItemButton onClick={() => {}}>
                <Tooltip title={t("Expected date")}>
                  <ListItemIcon>
                    <CalendarMonthIcon color="primary" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  primary={t("Expected date")}
                  secondary={
                    vehicle?.expectedDate
                      ? dayjs(vehicle.expectedDate).format("YYYY-MM-DD")
                      : " "
                  }
                />
              </ListItemButton>
            )}
          </Grid>
          {vehicle?.notes && (
            <Grid item xs={12} md={12}>
              <ListItemButton onClick={() => {}}>
                <Tooltip title={t("Notes")}>
                  <ListItemIcon>
                    <NotesIcon color="primary" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary={t("Notes")} secondary={vehicle?.notes} />
              </ListItemButton>
            </Grid>
          )}
          {vehicle?.adminNotes && !isClient && (
            <Grid item xs={12} md={12}>
              <ListItemButton onClick={() => {}}>
                <Tooltip title={t("Internal notes")}>
                  <ListItemIcon>
                    <NotesIcon color="primary" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  primary={t("Internal notes")}
                  secondary={vehicle?.adminNotes}
                />
              </ListItemButton>
            </Grid>
          )}
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <ListItemButton onClick={toggleDocumentsModal}>
                <Tooltip title={t("View documents")}>
                  <ListItemIcon>
                    <DocumentScannerIcon color="primary" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  primary={t("View documents")}
                  // secondary={vehicle?.adminNotes}
                />
              </ListItemButton>
            </Grid>
            <Grid item xs={12} md={6}>
              <ListItemButton onClick={toggleAddDocumentsModal}>
                <Tooltip title={t("Add documents")}>
                  <ListItemIcon color={getDocsColor}>
                    <DocumentScannerIcon color={getDocsColor} />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  primary={t("Add documents")}
                  // secondary={vehicle?.adminNotes}
                />
              </ListItemButton>
            </Grid>
            <Grid item xs={12} md={6}>
              {/* <GoogleDriveGallery /> */}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormDivider variant="fullWidth">
              {t("Title Information")}
            </FormDivider>
          </Grid>
          <Grid item xs={12} md={6}>
            <ListItemButton>
              <Tooltip title={t("Title received")}>
                <ListItemIcon>
                  <GradingIcon
                    color={`${vehicle?.titleReceived ? "primary" : "warning"}`}
                  />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={t("Title received")}
                secondary={vehicle?.titleReceived ? t("Yes") : t("No")}
              />
            </ListItemButton>
          </Grid>
          {vehicle?.titleReceivedDate && (
            <Grid item xs={12} md={6}>
              <ListItemButton>
                <Tooltip title={t("Title received date")}>
                  <ListItemIcon>
                    <CalendarMonthIcon color="primary" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  primary={t("Title received date")}
                  secondary={dayjs(vehicle?.titleReceivedDate).format(
                    "YYYY-MM-DD"
                  )}
                />
              </ListItemButton>
            </Grid>
          )}
          {vehicle?.titleSentDate && (
            <Grid item xs={12}>
              <ListItemButton onClick={() => {}}>
                <Tooltip title={t("Title sent date")}>
                  <ListItemIcon>
                    <ListAltIcon color="primary" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  primary={t("Title sent date")}
                  secondary={
                    vehicle?.titleSentDate
                      ? dayjs(vehicle.titleSentDate).format("YYYY-MM-DD")
                      : " "
                  }
                />
              </ListItemButton>
            </Grid>
          )}

          {vehicle?.handedOverTo && (
            <Grid item xs={12}>
              <ListItemButton onClick={() => {}}>
                <Tooltip title={t("Title given to")}>
                  <ListItemIcon>
                    <AccountBoxIcon color="primary" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  primary={t("Title given to")}
                  secondary={vehicle?.handedOverTo}
                />
              </ListItemButton>
            </Grid>
          )}

          <Grid item xs={12}>
            <FormDivider variant="fullWidth">
              {t("Container Information")}
            </FormDivider>
          </Grid>

          <Grid item xs={12} md={6}>
            <ListItemButton
              onClick={() => {
                trackContainer(containerState);
              }}
            >
              <Tooltip title={t("Container number")}>
                <ListItemIcon>
                  <GridViewIcon color="primary" />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={t("Container number")}
                secondary={containerState?.name || vehicle?.containerName}
              />
            </ListItemButton>
          </Grid>
          <Grid item xs={12} md={6}>
            <ListItemButton>
              <Tooltip title={t("Shipping company")}>
                <ListItemIcon>
                  <DirectionsBoatIcon color="primary" />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={t("Shipping company")}
                secondary={shippingLine?.name}
              />
            </ListItemButton>
          </Grid>
          <Grid item xs={12} md={6}>
            <ListItemButton>
              <Tooltip title={t("Expected date")}>
                <ListItemIcon>
                  <CalendarMonthIcon color="primary" />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={t("Expected date")}
                secondary={dayjs(vehicle?.container?.expectedDate).format(
                  "YYYY-MM-DD"
                )}
              />
            </ListItemButton>
          </Grid>
          <Grid item xs={12} md={6}>
            <ListItemButton>
              <Tooltip title={t("Notes")}>
                <ListItemIcon>
                  <NotesIcon color="primary" />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={t("Notes")}
                secondary={vehicle?.container?.notes}
              />
            </ListItemButton>
          </Grid>
          {vehicle?.pickupDate && (
            <>
              <Grid item xs={12}>
                <FormDivider variant="fullWidth">
                  {t("Pickup Information")}
                </FormDivider>
              </Grid>
              <Grid item xs={12} md={6}>
                <ListItemButton>
                  <Tooltip title={t("Pickup date")}>
                    <ListItemIcon>
                      <CalendarMonthIcon color="primary" />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={t("Pickup date")}
                    secondary={dayjs(vehicle?.pickupDate).format("YYYY-MM-DD")}
                  />
                </ListItemButton>
              </Grid>
              <Grid item xs={12} md={6}>
                <ListItemButton>
                  <Tooltip title={t("Company")}>
                    <ListItemIcon>
                      <EmojiTransportationIcon color="primary" />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={t("Company")}
                    secondary={vehicle?.truckCompany}
                  />
                </ListItemButton>
              </Grid>
              <Grid item xs={12} md={6}>
                <ListItemButton>
                  <Tooltip title={t("Driver name")}>
                    <ListItemIcon>
                      <AccountBoxIcon color="primary" />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={t("Driver name")}
                    secondary={vehicle?.driverName}
                  />
                </ListItemButton>
              </Grid>
              <Grid item xs={12} md={6}>
                <ListItemButton>
                  <Tooltip title={t("License plate")}>
                    <ListItemIcon>
                      <LocalShippingIcon color="primary" />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={t("License plate")}
                    secondary={vehicle?.licensePlate}
                  />
                </ListItemButton>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12} md={12}>
        <FormDivider variant="fullWidth">{t("Status History")}</FormDivider>
        {!isMobile ? (
          <StepperComponent
            activeStep={vehicle?.statusHistory?.length}
            steps={steps}
          />
        ) : (
          <VerticalStepper
            activeStep={vehicle?.statusHistory?.length}
            steps={steps}
          />
        )}
      </Grid>
      <VehicleDocumentsPreview
        data={vehicle}
        open={openDocModal}
        handleToggleOpen={toggleDocumentsModal}
      />
      <AddDocuments
        data={vehicle}
        open={openAddDocModal}
        handleToggleOpen={toggleAddDocumentsModal}
      />
    </>
  );
};
