import * as React from "react";
import Stepper, { StepperProps } from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { styled } from "@mui/material/styles";
import { ColorlibStepIcon } from ".";
import { Stack, StepConnector, stepConnectorClasses } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
  steps: any;
}
const StepperContainer = styled(Stepper)(() => ({
  alignItems: "self-start",
  margin: "0 auto",
  textAlign: "center",
  [`.${stepConnectorClasses.vertical}`]: {
    width: "70%",
  },
  [`.${stepConnectorClasses.root}`]: {
    position: "relative",
    left: "10px",
  },
}));
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: "#1976d2",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    background: "red",
    width: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));
const VerticalStepper: React.FC<StepperProps & Props> = ({
  steps,
  activeStep,
}) => {
  const { t } = useTranslation();
  return (
    <Stack sx={{ width: "100%" }} spacing={4}>
      <StepperContainer
        activeStep={activeStep}
        connector={<ColorlibConnector />}
        orientation="vertical"
      >
        {steps?.map((step: any, index: number) => (
          <Step key={step.label}>
            <StepLabel StepIconComponent={() => ColorlibStepIcon(step)}>
              {t(step.label)}
            </StepLabel>
          </Step>
        ))}
      </StepperContainer>
    </Stack>
  );
};
export default VerticalStepper;
