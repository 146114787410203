import * as React from "react";
import ImageGallery, {
  ReactImageGalleryItem,
  ReactImageGalleryProps,
} from "react-image-gallery";

import RestartAltIcon from "@mui/icons-material/RestartAlt";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

import styled from "styled-components";
import { Button } from "@mui/material";

interface IGalleryProps {
  removeImage?: (item: ReactImageGalleryItem) => void;
}

const FullscreenWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  @media (max-width: 768px) {
    img {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 90%;
      object-fit: contain;
    }
  }
`;
const ZoomControls = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 10;
  display: flex;
  gap: 10px;
  background: #000;
  border-radius: 16px;
`;

export const ImageGalleryComponent = React.memo(function Images(
  props: IGalleryProps & ReactImageGalleryProps
) {
  return <ImageGalleryRenderer removeImage={props.removeImage} {...props} />;
});

const ImageGalleryRenderer: React.FC<
  IGalleryProps & ReactImageGalleryProps
> = ({ items, showThumbnails = true, removeImage }) => {
  const [isFullscreen, setIsFullscreen] = React.useState(false);

  const handleScreenChange = (fullScreen: boolean) => {
    setIsFullscreen(fullScreen);
  };
  if (!items) {
    return <></>;
  }

  const renderGalleryItem = (item: ReactImageGalleryItem) => {
    if (isFullscreen) {
      return (
        <TransformWrapper
          initialScale={1}
          minScale={1}
          maxScale={3}
          initialPositionX={0}
          initialPositionY={0}
        >
          {({ zoomIn, zoomOut, resetTransform }) => (
            <>
              <ZoomControls>
                <Button
                  style={{ color: "#fff" }}
                  variant="text"
                  onClick={() => zoomIn()}
                  aria-label="zoom in"
                >
                  <ZoomInIcon />
                </Button>

                <Button
                  style={{ color: "#fff" }}
                  variant="text"
                  onClick={() => zoomOut()}
                  aria-label="zoom out"
                >
                  <ZoomOutIcon />
                </Button>
                <Button
                  color="error"
                  onClick={() => resetTransform()}
                  variant="text"
                  aria-label="reset"
                >
                  <RestartAltIcon />
                </Button>
              </ZoomControls>
              <FullscreenWrapper>
                <TransformComponent
                  wrapperStyle={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "hidden",
                  }}
                  contentStyle={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                >
                  <img
                    src={item.original}
                    alt={item.originalAlt}
                    srcSet={item.srcSet}
                    sizes={item.sizes}
                    className="image-gallery-image"
                  />
                </TransformComponent>
              </FullscreenWrapper>
            </>
          )}
        </TransformWrapper>
      );
    }

    return (
      <img
        src={item.original}
        alt={item.originalAlt}
        srcSet={item.srcSet}
        sizes={item.sizes}
        className="image-gallery-image"
      />
    );
  };

  // const filteredItems = items.filter((item: any) => {
  //   return typeof item.original === "string";
  // });
  return (
    <ImageGallery
      lazyLoad
      showPlayButton={false}
      showThumbnails={false}
      renderItem={renderGalleryItem}
      // renderThumbInner={renderGalleryThumbnail}
      items={items}
      onScreenChange={handleScreenChange}
    />
  );
};
